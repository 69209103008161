<template>
  <ion-page class="page scrollable bg-transparent">
    <div class="d-flex justify-content-between align-items-center flex-wrap py-3">
      <div class="title">My Drafts</div>
    </div>
    <DraftTabs class="my-2" :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />
    <div class="count mt-3 mb-1" v-if="!loading">
      <span v-if="currentTab === 'characters'">Draft Characters ({{ charactersCount }})</span>
      <span v-if="currentTab === 'worlds'">Draft Worlds ({{ worldsCount }})</span>
    </div>
    <InfiniteCharacters
      v-show="currentTab === 'characters'"
      layoutChanger
      class="my-2"
      :characters="characterDrafts"
      :hideTypeTag="true"
      :paging="paging"
      :loading="loading"
      @load="requestLoadMore"
    />
    <InfiniteWorlds
      v-show="currentTab === 'worlds'"
      class="my-2"
      :worlds="worldDrafts"
      :paging="paging"
      :loading="loading"
      @load="requestLoadMore"
    />
  </ion-page>
</template>

<script lang="ts" setup>
import DraftTabs from './DraftTabs.vue';
import InfiniteCharacters from '@/shared/components/InfiniteCharacters.vue';
import InfiniteWorlds from '@/shared/components/InfiniteWorlds.vue';
import { draftsTabs } from '@/shared/statics/tabs';
import { getCharactersDraft } from '@/shared/actions/characters';
import { Paging, Tab } from '@/shared/types/static-types';
import { getWorldDrafts } from '@/shared/actions/worlds';
import { getNextPage } from '@/shared/helpers/pagination';

const tabs = draftsTabs;

const currentTab = ref('characters');
const tabIndex = ref(0);
const characterDrafts = ref([]);
const worldDrafts = ref([]);
const paging = ref<Paging | null>(null);
const loading = ref(true);
const charactersCount = ref(0);
const worldsCount = ref(0);

const tabChanged = ({ value }: Tab) => {
  const router = useRouter();
  currentTab.value = value;
  router.replace({ name: 'drafts', query: { tab: value } });
  requestLoadInit();
};

const fetchCharactersDraft = async (page = 1) => {
  try {
    loading.value = true;
    const { results, ...p } = await getCharactersDraft('draft', page);
    characterDrafts.value = results;
    charactersCount.value = p.count;
    paging.value = p;
  } catch (e) {
  } finally {
    loading.value = false;
  }
};

const fetchWorldDrafts = async (page = 1) => {
  try {
    loading.value = true;
    const { results, ...p } = await getWorldDrafts('story', page);
    worldsCount.value = p.count;
    worldDrafts.value = results;
    paging.value = p;
  } catch (e) {
  } finally {
    loading.value = false;
  }
};

// Define other fetch methods similarly

const requestLoadInit = async () => {
  if (!currentTab.value) currentTab.value = 'characters';
  if (currentTab.value === 'characters') await fetchCharactersDraft();
  else if (currentTab.value === 'worlds') await fetchWorldDrafts();
};

const requestLoadMore = async () => {
  try {
    const { results, ...p } = await getNextPage(paging.value!);
    if (currentTab.value === 'characters') characterDrafts.value = characterDrafts.value.concat(results);
    if (currentTab.value === 'worlds') worldDrafts.value = worldDrafts.value.concat(results);
    paging.value = p;
  } catch (e) {}
};

const route = useRoute();

const tIndex = tabs.findIndex((tab) => tab.value === route.query.tab);
if (route.query.tab && tIndex !== -1) {
  tabIndex.value = tIndex;
  currentTab.value = String(route.query.tab);
  requestLoadInit();
} else {
  fetchCharactersDraft();
}
</script>

<style lang="sass" scoped>
.count
  font-size: 20px
  font-weight: bold
.title
  font-size: 24px
  font-weight: bold
  margin-left: 0.5rem
</style>
